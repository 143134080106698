.ql-container {
  border: none !important;
}

.ql-editor {
  padding-left: 0;
  padding-right: 0;
}

.ql-editor::before {
  left: 0 !important;
}
